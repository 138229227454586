
import React from 'react';

import { BrowserRouter as Router, Route } from "react-router-dom";

import Admin from './Admin'

const App = () => (
    <Router>
        <div>
            <Route exact path="/" component={() => (<Admin />)} />
            <Route path='/status' exact component={() => (<div>Status: OK</div>)} />
        </div>
    </Router>
);

// const App = () => <Admin />

export default App;
