import * as React from 'react'
import { FC, AnchorHTMLAttributes, memo } from 'react'
import get from 'lodash/get'
import { sanitizeFieldRestProps } from 'react-admin'
import { Typography, Link } from '@material-ui/core'

const points = (str) => {
    if (str) {
        if (str.length > 48)
            return str.substring(0, 24) + '...' + str.substring(str.length - 24)
    }
    return str
}

const UrlField = memo(
    ({ className, emptyText, source, record = {}, ...rest }) => {
        const value = get(record, source)

        if (value == null && emptyText) {
            return (
                <Typography
                    component="span"
                    variant="body2"
                    className={className}
                    {...sanitizeFieldRestProps(rest)}
                >
                    {emptyText}
                </Typography>
            )
        }

        return (
            <Link
                className={className}
                href={value}
                {...sanitizeFieldRestProps(rest)}
            >
                {points(value)}
            </Link>
        )
    }
)

UrlField.defaultProps = {
    addLabel: true,
}

UrlField.displayName = 'UrlField'

export default UrlField