
import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Title } from 'react-admin';
import { useLocale, useSetLocale, useTranslate } from 'react-admin'
import ChangePassword from './Customs/ChangePassword'

export default (endpoint) => {

    const A = function () {
        const locale = useLocale()
        const setLocale = useSetLocale()
        const translate = useTranslate();
        return <div>
            <Card style={{ margin: "5px" }}>
                <Title title={translate('ra.customs.dashboard_title')} />
                <CardContent>{translate('ra.customs.dashboard_welcome')}</CardContent>
            </Card>
            <Card style={{ margin: "5px" }}>
                <ChangePassword />
            </Card>
            {/* <Card style={{ margin: "5px" }}>
                <CardContent>{translate('ra.customs.change_lang')}</CardContent>
                <Button style={{ margin: "5px" }}
                    disabled={locale === 'fr'}
                    onClick={() => {
                        setLocale('fr')
                        localStorage.setItem('locale', 'fr')
                    }}
                >
                    FR
                </Button>
                <Button style={{ margin: "5px" }}
                    disabled={locale === 'en'}
                    onClick={() => {
                        setLocale('en')
                        localStorage.setItem('locale', 'en')
                    }}
                >
                    EN
                </Button>
            </Card> */}
        </div>

    }

    const mapping = {
        "Main": A,
    }

    return mapping[endpoint] || A
}
