import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'ra-core';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FlatButton from '@material-ui/core/Button'
import PasswordIcon from '@material-ui/icons/Lock';
import { showNotification } from 'react-admin';
import { push as pushAction } from 'react-router-redux'

const API_URL = process.env.REACT_APP_API_HOSTNAME;

const styles = {
    textField: {
        display: 'block'
    },
    textLabel: {
        display: 'block',
        fontSize: "12px"
    },
};

class ChangePassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            message: '',
            messageColor: "grey",
            message2: '',
            messageColor2: "grey"
        };
        this.handleOpen = this.handleOpen.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleChangeInput1 = this.handleChangeInput1.bind(this);
        this.handleChangeInput2 = this.handleChangeInput2.bind(this);
    }

    handleOpen() {
        this.setState({ open: true, message: '', messageColor: 'grey', message2: '', messageColor2: '' });
    }

    handleSubmit() {
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        requestHeaders.set("Content-Type", "application/json")
        fetch(API_URL + "/superuser/change-password", {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify({
                token: localStorage.getItem("token"),
                old_password: document.getElementById("old_password-input").value,
                password: document.getElementById("password-input-1").value
            })
        }).then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        ).then(({ status, statusText, headers, body }) => {
            let json;
            try {
                json = JSON.parse(body)
            } catch (e) {
                console.error(e)
            }
            if (status === 200) {
                this.props.showNotification(this.props.translate('ra.customs.pwd_succesfully_changed'))
                this.setState({open: false})
            } else {
                this.props.showNotification(this.props.translate('ra.customs.error_occured'))
            }
        });
    }

    handleClose() {
        this.setState({ open: false });
    }

    handleChangeInput1(event) {
        const translate = this.props.translate;
        const value = event.target.value
        const noobRegex = new RegExp("^(?=.*[a-z])");
        const weakRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");
        const regularRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])");
        const masterRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        if (value === '') {
            this.setState({ message: '' })
        } else if (masterRegex.test(value)) {
            this.setState({ message: translate('ra.customs.strong_password'), messageColor: "green" })
        } else if (strongRegex.test(value)) {
            this.setState({ message: translate('ra.customs.minimum_8_characters'), messageColor: "orange" })
        } else if (regularRegex.test(value)) {
            this.setState({ message: translate('ra.customs.missing_special_character'), messageColor: "orange" })
        } else if (weakRegex.test(value)) {
            this.setState({ message: translate('ra.customs.missing_numeric_character'), messageColor: "red" })
        } else if (noobRegex.test(value)) {
            this.setState({ message: translate('ra.customs.missing_capital_character'), messageColor: "red" })
        } else {
            this.setState({ message: translate('ra.customs.missing_alphabetic_character'), messageColor: "red" })
        }
    }

    handleChangeInput2() {
        const translate = this.props.translate;
        const value1 = document.getElementById('password-input-1').value
        const value2 = document.getElementById('password-input-2').value
        if (value2 === '') {
            this.setState({ message2: '' })
        } else if (value1 === value2) {
            this.setState({ message2: translate('ra.customs.same_passwords'), messageColor2: "green" })
        } else {
            this.setState({ message2: translate('ra.customs.different_passwords'), messageColor2: "red" })
        }
    }

    render() {
        const translate = this.props.translate;
        return (
            <div className="container-fluid" >
                <FlatButton
                    className="logout"
                    startIcon={<PasswordIcon />}
                    style={{ margin: "5px" }}
                    onClick={this.handleOpen}
                >
                    {translate('ra.customs.change_password')}
                </FlatButton>
                <Dialog
                    open={this.state.open}
                >
                    <DialogTitle id="form-dialog-title">{translate('ra.customs.change_password')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            id="old_password-input"
                            label={translate('ra.customs.old_password')}
                            style={styles.textField}
                            type="password"
                            margin="normal"
                        />
                        <TextField
                            id="password-input-1"
                            label={translate('ra.customs.new_password')}
                            style={styles.textField}
                            type="password"
                            margin="normal"
                            onChange={this.handleChangeInput1}
                        />
                        <span
                            id="password-label-1"
                            style={{ ...styles.textLabel, color: this.state.messageColor }}
                            margin="normal"
                        >
                            {this.state.message}
                        </span>
                        <TextField
                            id="password-input-2"
                            label={translate('ra.customs.repeat_password')}
                            style={styles.textField}
                            type="password"
                            margin="normal"
                            onChange={this.handleChangeInput2}
                        />
                        <span
                            id="password-label-2"
                            style={{ ...styles.textLabel, color: this.state.messageColor2 }}
                            margin="normal"
                        >
                            {this.state.message2}
                        </span>
                    </DialogContent>
                    <DialogActions>
                        <FlatButton
                            primary={true}
                            onClick={this.handleSubmit}
                            disabled={!(this.state.messageColor === 'green' && this.state.messageColor2 === 'green')}
                        >
                            {translate('ra.customs.submit')}
                        </FlatButton>
                        <FlatButton
                            primary={true}
                            onClick={this.handleClose}
                        >
                            {translate('ra.customs.cancel')}
                        </FlatButton>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

ChangePassword.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification: showNotification,
    push: pushAction,
})(translate(ChangePassword));
