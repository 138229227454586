import * as React from 'react';
import { FC, memo, useMemo, ReactElement, useState } from 'react';
import PropTypes from 'prop-types';
import ImageEye from '@material-ui/icons/AutorenewOutlined';
import ImageEye2 from '@material-ui/icons/LoopOutlined';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';
import { stringify } from 'query-string'

import { Button } from 'react-admin';

const API_URL = process.env.REACT_APP_API_HOSTNAME;


const ShowButton = ({
    resource,
    basePath = '',
    icon = defaultIcon,
    label = 'Sync Inpi',
    record,
    scrollToTop = true,
    ...rest
}) => {

    const [state, setState] = useState({
        loading: false,
    });

    return (
        <Button
            // component={Link}
            // to={useMemo(
            //     () => ({
            //         pathname: record
            //             ? `${linkToRecord(basePath, record.id)}/show`
            //             : '',
            //         state: { _scrollToTop: scrollToTop },
            //     }),
            //     [basePath, record, scrollToTop]
            // )}
            label={label}
            disabled={record.locked || state.loading}
            onClick={() => {
                setState({ loading: true })
                const requestHeaders = new Headers()
                requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
                requestHeaders.set("Content-Type", "application/json")
                fetch(`${API_URL}/superuser/company/updateByInpi?${stringify({ siren: record.siren })}`, {
                    method: "GET",
                    headers: requestHeaders,
                }).then(response =>
                    response.text().then(text => ({
                        status: response.status,
                        statusText: response.statusText,
                        headers: response.headers,
                        body: text,
                    }))
                ).then(({ status, statusText, headers, body }) => {
                    let json;
                    try {
                        json = JSON.parse(body)
                        console.log({ json })
                    } catch (e) {
                        console.error(e)
                    }
                    setState({ loading: false })
                    if (status === 200) {
                    } else {
                    }
                });
            }}
            {...(rest)}
        >
            {state.loading ? <ImageEye2 /> : <ImageEye />}
        </Button>
    )
};

const defaultIcon = <ImageEye />;

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation();

ShowButton.propTypes = {
    basePath: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
    record: PropTypes.any,
    scrollToTop: PropTypes.bool,
};

const PureShowButton = memo(
    ShowButton,
    (props, nextProps) =>
        (props.record && nextProps.record
            ? props.record.id === nextProps.record.id
            : props.record == nextProps.record) && // eslint-disable-line eqeqeq
        props.basePath === nextProps.basePath &&
        props.to === nextProps.to &&
        props.disabled === nextProps.disabled
);

export default PureShowButton;