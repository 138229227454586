import * as React from 'react';
import { FC, ReactElement, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Fab, useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContentAdd from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FlatButton from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslate } from 'ra-core';

import { Button } from 'react-admin';

import dataProvider from '../dataProvider'

const sanitizeButtonRestProps = ({
    // The next props are injected by Toolbar
    basePath,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    onSave,
    pristine,
    record,
    redirect,
    resource,
    saving,
    submitOnEnter,
    undoable,
    ...rest
}) => rest;

const CreateButton = props => {
    const {
        basePath = '',
        className,
        classes: classesOverride,
        label = 'ra.action.delete',
        icon = defaultIcon,
        variant,
        ...rest
    } = props;
    const classes = useStyles(props);
    const translate = useTranslate();
    const isSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('sm')
    );
    const [state, setState] = useState({
        completed: 0,
        open1: false,
        open2: false,
        determinate: true
    });
    let buffer = []
    let totalSize = 0
    let currentSize = 0

    const deleteBuffer = (index = 0) => {
        const { resource } = props
        const elem = buffer[index]
        dataProvider.delete(resource, { id: elem.id })
            .then((response) => {
                currentSize--
                setState({
                    open1: false, open2: true, determinate: true,
                    completed: 100 * (totalSize - currentSize) / totalSize
                })
                if (index < buffer.length - 1) {
                    setTimeout(() => deleteBuffer(index + 1), 1)
                } else {
                    buffer = []
                    setTimeout(() => downloadPage(), 1)
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }

    const downloadPage = (page = 1) => {
        const perPage = 100
        const { resource, getFilterValues } = props
        const filterValues = getFilterValues()
        dataProvider.getList(resource, { pagination: { page: page, perPage: perPage }, sort: {}, filter: filterValues })
            .then((response) => {
                if (totalSize === 0)
                    totalSize = response.total
                currentSize = response.total
                setState({
                    open1: false, open2: true, determinate: true,
                    completed: 100 * (totalSize - currentSize) / totalSize
                })
                if ((page - 1) * perPage < response.total && (state.open1 || state.open2)) {
                    response.data.forEach(elem => buffer.push(elem))
                    setTimeout(() => deleteBuffer(), 1)
                } else {
                    totalSize = 0
                    setState({ open1: false, open2: false, determinate: true, completed: 0 })
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }

    return <div>
        {
            isSmall ? (
                <Fab
                    onClick={() => setState({ open1: true, open2: false, determinate: true, completed: 0 })}
                    className={classnames(
                        'ra-delete-button',
                        classes.floating,
                        className
                    )}
                    aria-label={label && translate(label)}
                    {...sanitizeButtonRestProps(rest)}
                >
                    {icon}
                </Fab>
            ) : (
                    <Button
                        onClick={() => setState({ open1: true, open2: false, determinate: true, completed: 0 })}
                        className={classnames(
                            'ra-delete-button',
                            classes.nofloating,
                            className
                        )}
                        label={label}
                        variant={variant}
                        {...(rest)}
                    >
                        {icon}
                    </Button>
                )
        }
        <Dialog
            open={state.open1}
        >
            <DialogTitle id="form-dialog-title">{translate("ra.customs.delete_confirmation")}</DialogTitle>
            <DialogActions>
                <FlatButton onClick={() => {
                    setState({ open1: false, open2: true, determinate: true, completed: 0 })
                    setTimeout(() => downloadPage(), 1)
                }} color="primary">
                    {translate('ra.boolean.true')}
                </FlatButton>
                <FlatButton onClick={() => setState({ open1: false, open2: false, determinate: true, completed: 0 })} color="primary">
                    {translate('ra.boolean.false')}
                </FlatButton>
            </DialogActions>
        </Dialog>
        <Dialog
            open={state.open2}
        >
            <DialogTitle id="form-dialog-title">{translate("ra.customs.deleting")}</DialogTitle>
            <DialogContent>
                <LinearProgress variant={state.determinate ? "determinate" : "indeterminate"} value={state.completed} />
            </DialogContent>
        </Dialog>
    </div>
};

const defaultIcon = <ContentAdd />;

const useStyles = makeStyles(
    theme => ({
        nofloating: {
            color: theme.palette.error.main,
        },
        floating: {
            color: theme.palette.error.main,
            margin: 0,
            top: 'auto',
            right: 100,
            bottom: 60,
            left: 'auto',
            position: 'fixed',
            zIndex: 1000,
        },
        floatingLink: {
            color: 'inherit',
        },
    }),
    { name: 'RaDeleteWithUndoButton' }
);

CreateButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    icon: PropTypes.element,
    label: PropTypes.string,
};

export default memo(CreateButton, (prevProps, nextProps) => {
    return (
        prevProps.basePath === nextProps.basePath &&
        prevProps.label === nextProps.label &&
        prevProps.translate === nextProps.translate &&
        prevProps.to === nextProps.to
    );
});

