import React from 'react'
import { Component, FunctionComponent, useState } from 'react'
import PropTypes from 'prop-types'
import { useInput, FieldTitle, InputProps } from 'ra-core'
import { TextFieldProps } from '@material-ui/core/TextField'
import ChipInput from 'material-ui-chip-input'

import { ResettableTextField, InputHelperText, sanitizeInputRestProps } from 'react-admin'


const TextInput = (props) => {
    const {
        label,
        className,
        type,
        format,
        helperText,
        onBlur,
        onFocus,
        onChange,
        options,
        parse,
        resource,
        source,
        record,
        validate,
        id,
        ...rest
    } = props

    const {
        input,
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        validate,
        ...rest,
    });

    const [values, setValues] = useState(record[source]);

    return (
        <ChipInput
            id={id}
            {...input}
            allowDuplicates={true}
            value={values}
            className={className}
            type={type}
            onAdd={(value) => {
                setValues([...values, value])
                input.onChange([...values, value])
            }}
            onDelete={(_, index) => {
                setValues(values.filter((_, i) => i !== index))
                input.onChange(values.filter((_, i) => i !== index))
            }}
            onBlur={() => input.onBlur()}
            label={
                <FieldTitle
                    label={label}
                    source={source}
                    resource={resource}
                    isRequired={isRequired}
                />
            }
            error={!!(touched && (error || submitError))}
            {...options}
            {...sanitizeInputRestProps(rest)}
        />
    )
}

TextInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
}

TextInput.defaultProps = {
    options: {},
}

export default TextInput