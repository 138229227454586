import * as React from 'react'
import { FC, memo } from 'react'
import get from 'lodash/get'
import Chip, { ChipProps } from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import { sanitizeFieldRestProps } from 'react-admin'

const points = (str) => {
    if (str.length > 24)
        return str.substring(0, 24) + '...'
    return str
}


const useStyles = makeStyles(
    {
        chip: { margin: 4, cursor: 'inherit' },
    },
    { name: 'RaChipField' }
)

export const ChipField = memo(props => {
    const {
        className,
        classes: classesOverride,
        source,
        record = {},
        emptyText,
        ...rest
    } = props
    const classes = useStyles(props)
    const value = get(record, source)

    if (value == null && emptyText) {
        return (
            <Typography
                component="span"
                variant="body2"
                className={className}
                {...sanitizeFieldRestProps(rest)}
            >
                {emptyText}
            </Typography>
        )
    }

    const items = []
    let data = get(record, source)
    if (data) {
        if (!Array.isArray(data))
            data = [data]
        data.forEach((elem, index) => {
            items.push(
                <Chip
                    className={classnames(classes.chip, className)}
                    label={points(elem)}
                    {...sanitizeFieldRestProps(rest)}
                />
            )
        })
    }


    return (
        items
    )
})

ChipField.defaultProps = {
    addLabel: true,
}

ChipField.propTypes = {
    ...ChipField.propTypes,
}

export default ChipField