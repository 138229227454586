
import {
    fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'

const API_URL = process.env.REACT_APP_API_HOSTNAME

const mapGet = {
    'User': 'superuser/users',
    'Command': 'superuser/commands',
    'Preuser': 'superuser/preusers',
    'Dynamicplatform': 'superuser/dynamicplatforms',
    'Dynamicadvertising': 'superuser/dynamicadvertisings',
    'Dynamicaffiliation': 'superuser/dynamicaffiliations',
    'Dynamicincomeother': 'superuser/dynamicincomeothers',
    'Doodle': 'superuser/doodles',
    'Paymentuser': 'superuser/paymentusers',
}

const mapPost = {
    'User': 'superuser/user',
    'Command': 'superuser/command',
    'Preuser': 'superuser/preuser',
    'Dynamicplatform': 'superuser/dynamicplatform',
    'Dynamicadvertising': 'superuser/dynamicadvertising',
    'Dynamicaffiliation': 'superuser/dynamicaffiliation',
    'Dynamicincomeother': 'superuser/dynamicincomeother',
    'Doodle': 'superuser/doodle',
    'Paymentuser': 'superuser/paymentuser',
}


const { fetchJson } = fetchUtils

const formify = (params) => {
    const formData = new FormData();
    for (let i in params.data) {
        if (params.data[i] && params.data[i].rawFile) {
            formData.append(i, params.data[i].rawFile);
        } else {
            if (Array.isArray(params.data[i]))
                formData.append(i, JSON.stringify(params.data[i]));
            else
                formData.append(i, params.data[i]);
        }
    }
    return formData;
};

const dataProvider = {
    getList: (resource, params) => new Promise(resolve => {
        console.log('getList', { resource, params })
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
            limit: perPage,
            offset: (page - 1) * perPage,
            sort_field: field,
            sort_order: order,
            ...params.filter
        }
        const url = `${API_URL}/${mapGet[resource]}?${stringify(query)}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            json = JSON.parse(json.body)
            // if (resource === 'User') {
            //     resolve({
            //         data: json.data,
            //         total: json.total
            //     })
            // }
            resolve({
                data: json.data,
                total: json.total
            })
        })
    }),
    getOne: (resource, params) => new Promise(resolve => {
        console.log('getOne', { resource, params })
        const url = `${API_URL}/${mapGet[resource]}?${stringify({ id: params.id })}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            json = JSON.parse(json.body)
            // if (resource === 'User') {
            //     resolve({
            //         data: json.data[0],
            //     })
            // }
            resolve({
                data: json.data[0],
            })
        })
    }),
    getMany: (resource, params) => new Promise(async (resolve) => {
        console.log('getMany', { resource, params })
        const result = []
        for (let i = 0; i < params.ids.length; i++) {
            const ret = await dataProvider.getOne(resource, { id: params.ids[i] })
            if (ret.data)
                result.push(ret.data)
        }
        console.log(result)
        resolve({ data: result })
    }),
    getManyReference: (resource, params) => new Promise(resolve => {
        console.log('getManyReference', { resource, params })
        resolve()
    }),
    create: (resource, params) => new Promise(resolve => {
        console.log('create', { resource, params })
        if (resource === 'Professor') {
            // params.data.lastCrawlDate = new Date(params.data.lastCrawlDate).getTime()
        }
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'POST'
        if (resource === 'Dynamicplatform' || resource === 'Doodle') {
            options.body = formify(params)
        } else {
            options.body = JSON.stringify(params.data)
        }
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.data, id: json.id } })
        })
    }),
    update: (resource, params) => new Promise(resolve => {
        console.log('update', { resource, params })
        delete params.data.updatedAt
        delete params.data.createdAt
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'PUT'
        options.body = JSON.stringify(params.data)
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.data } })
        })
    }),
    updateMany: (resource, params) => new Promise(resolve => {
        console.log('updateMany', { resource, params })
        resolve()
    }),
    delete: (resource, params) => new Promise(resolve => {
        console.log('delete', { resource, params })
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        const options = { headers: requestHeaders }
        options.method = 'DELETE'
        options.body = JSON.stringify({ id: params.id })
        fetchJson(url, options).then(json => {
            json = JSON.parse(json.body)
            resolve({ data: { ...params.id } })
        })
    }),
    deleteMany: (resource, params) => new Promise(async (resolve) => {
        console.log('deleteMany', { resource, params })
        for (let i = 0; i < params.ids.length; i++) {
            await dataProvider.delete(resource, { id: params.ids[i] })
        }
        resolve({ data: [...params.ids] })
    }),
}

export default dataProvider
