
// in app.js
import * as React from "react"
import { Admin, Resource, DeleteButton } from 'react-admin'
import dataProvider from './dataProvider'
import authProvider from './authProvider';
import { i18nProvider as polyglotI18nProvider } from './polyglotProvider'
import Dashboard from './Dashboard'
import Theme from './Theme'
import LoginPage from './LoginPage'
import Menu from './Menu'

import { UserList, UserShow, UserCreate, UserEdit, UserIcon } from './user'
import { CommandList, CommandShow, CommandEdit, CommandIcon } from './resources/command'
import { PreuserList, PreuserShow, PreuserCreate, PreuserIcon } from './preuser'
import { DynamicplatformList, DynamicplatformShow, DynamicplatformCreate, DynamicplatformIcon, DynamicplatformEdit } from './dynamicplatform'
import { DynamicadvertisingList, DynamicadvertisingShow, DynamicadvertisingCreate, DynamicadvertisingIcon, DynamicadvertisingEdit } from './dynamicadvertising'
import { DynamicaffiliationList, DynamicaffiliationShow, DynamicaffiliationCreate, DynamicaffiliationIcon, DynamicaffiliationEdit } from './dynamicaffiliation'
import { DynamicincomeotherList, DynamicincomeotherShow, DynamicincomeotherCreate, DynamicincomeotherIcon, DynamicincomeotherEdit } from './dynamicincomeother'
import { DoodleList, DoodleShow, DoodleCreate, DoodleIcon, DoodleEdit } from './doodle'
import { PaymentuserList, PaymentuserShow, PaymentuserCreate, PaymentuserIcon, PaymentuserEdit } from './paymentuser'

import { BrowserRouter as Router, Route } from "react-router-dom";


import { Provider } from 'react-redux';
import { createHashHistory } from 'history';
import createAdminStore from './createAdminStore';

const history = createHashHistory();


class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      dashboard: null
    }
  }

  render() {
    return (
      <Provider
        store={createAdminStore({
          authProvider,
          dataProvider,
          history,
        })}
      >
        <Admin
          theme={Theme}
          menu={Menu((dashboard) => this.setState({ dashboard }))}
          dataProvider={dataProvider}
          authProvider={authProvider}
          dashboard={Dashboard(this.state.dashboard)}
          i18nProvider={polyglotI18nProvider}
          loginPage={LoginPage}
          history={history}
          customRoutes={[
          ]}
        >
          {/* <Resource name="Preuser" options={{ group: "users" }} list={PreuserList} show={PreuserShow} icon={PreuserIcon} remove={DeleteButton} /> */}
          <Resource name="User" options={{ group: "users" }} list={UserList} show={UserShow} edit={UserEdit} icon={UserIcon} remove={DeleteButton} />
          {/* <Resource name="Command" options={{ group: "users" }} list={CommandList} show={CommandShow} edit={CommandEdit} icon={CommandIcon} /> */}
          {/* <Resource name="Paymentuser" options={{ group: "users" }} list={PaymentuserList} show={PaymentuserShow} edit={PaymentuserEdit} icon={PaymentuserIcon} />
          <Resource name="Dynamicplatform" options={{ group: "options" }} list={DynamicplatformList} show={DynamicplatformShow} create={DynamicplatformCreate} edit={DynamicplatformEdit} icon={DynamicplatformIcon} remove={DeleteButton} />
          <Resource name="Dynamicadvertising" options={{ group: "options" }} list={DynamicadvertisingList} show={DynamicadvertisingShow} create={DynamicadvertisingCreate} edit={DynamicadvertisingEdit} icon={DynamicadvertisingIcon} remove={DeleteButton} />
          <Resource name="Dynamicaffiliation" options={{ group: "options" }} list={DynamicaffiliationList} show={DynamicaffiliationShow} create={DynamicaffiliationCreate} edit={DynamicaffiliationEdit} icon={DynamicaffiliationIcon} remove={DeleteButton} />
          <Resource name="Dynamicincomeother" options={{ group: "options" }} list={DynamicincomeotherList} show={DynamicincomeotherShow} create={DynamicincomeotherCreate} edit={DynamicincomeotherEdit} icon={DynamicincomeotherIcon} remove={DeleteButton} />
          <Resource name="Doodle" options={{ group: "options" }} list={DoodleList} show={DoodleShow} create={DoodleCreate} edit={DoodleEdit} icon={DoodleIcon} remove={DeleteButton} /> */}
        </Admin>
      </Provider>
    )
  }
}

export default App
